<template>
  <section>
    <div class="module">
        <div class="title">
          您的订单已经生成，我们将在1-3个工作日内与您联系！
        </div>
        <div class="opt fx">
          <div @click="tohome" class="opt_btn f1 faj">去首页</div>
          <div @click="tolist" class="opt_btn f1 faj">查看订单</div>
        </div>
      </div>
  </section>
</template>
<script>
import Vue from "vue";
import { NavBar } from "vant";
Vue.use(NavBar);
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data() {
    return {
      time: 5,
    };
  },
  computed: {},
  methods: {
    getNumber() {
      this.setTime = setInterval(() => {
        if (this.time == 0) {
          this.$router.replace("dataList");
          clearInterval(this.setTime);
        } else {
          this.time = this.time - 1;
        }
      }, 1000);
    },
    tohome(){
      this.$router.replace("home");
    },
    tolist(){
      this.$router.replace("dataList");
    }
  },
  created() {},
  mounted() {
    // this.getNumber();
  },
};
</script>
<style scoped>
.module{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
  width: 370px;
  height: 200px;
  box-sizing: border-box;
  padding: 20px 15px;
  border-radius: 8px;
  background: #fff;
}
.opt{
  position: relative;
}
.opt_btn{
  height: 40px;
  border-radius: 100px;
  background: #0078d7;
  font-size: 14px;
  color: #fff;
}
.opt_btn:first-child{
  margin-right: 20px;
}
.title {
  padding: 0 15px 15px 15px;
  color: #333;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
}
.text {
  font-size: 14px;
  color: #333;
  text-align: center;
  padding: 0 15px;
}
.num {
  color: red;
}
</style>
